body {
  height: 100vh;
  margin: 0;
  flex-grow: 0;
  font-family: "Heebo", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
* {
  font-family: "Heebo", sans-serif !important;
}

/* remove input arrow */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.is-sticky {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    border: solid 1px #eaeaea;
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
